
import { eoRendererMixin, metaDataMixin } from '~/mixins'

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  async asyncData ({ $api, $config, $log, store, error }) {
    const slug = 'verantwoording-en-jaarverslagen'

    const {
      data: {
        items: [
          page = {},
        ] = [],
      } = {},
    } = await $api.pages.getOneBySlug(slug, {
      domain: $config.preprEnvironmentDomain,
    })

    // check publication if exists and is complete
    if (!page?.id) {
      $log.warn(`Couldn't create '${slug}' page.`)
      return error({ statusCode: 404, message: 'Oeps! De pagina is niet gevonden' })
    }

    store.dispatch('breadcrumbs/set', {
      path: `/${slug}`,
      width: 6,
      items: [
        {
          to: `/`,
          label: 'Over de EO',
        },
        {
          label: page.title,
        },
      ],
    })
    return page
  },
  data () {
    return {
      id: null,
      title: null,
      subtitle: null,
      intro: null,
      hero: null,
      featuredVideo: null,
      content: null,
      seo: null,
      slug: null,
      social: null,
    }
  },
}
